import React from 'react'
import TextField from '@material-ui/core/TextField';
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import SendIcon from '@material-ui/icons/Send';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) =>
  createStyles({
    wrapForm : {
        display: "flex",
        justifyContent: "center",
        width: "88%",
        margin: `${theme.spacing(0)} auto`
    },
    wrapText  : {
        width: "100%"
    },
    button: {
		color: "white",
		backgroundColor: "#e91e63"
        //margin: theme.spacing(1),
    },
  })
);

export const TextInput = ({backToApp}) => {
    const [message, setMessage] = React.useState("");
    const sendMessage = () => {
        console.log("====firebase====", message);
        setMessage("")
        backToApp(message);
    }
    const handleChange = (event) => {
        setMessage(event.target.value)
    }
    const classes = useStyles();
    return (
        <>
            <form className={classes.wrapForm}  noValidate autoComplete="off">
                <TextField
                    id="standard-text"
                    label="Chat with the delivery driver"
                    className={classes.wrapText}
                    value={message}
                    onChange={handleChange}
                    //margin="normal"
                />
                <Button variant="contained" color="rose" className={classes.button} onClick={sendMessage}>
                    <SendIcon />
                </Button>
            </form>
        </>
    )
}